AOS.init({ disable: "mobile" });

const header = document.querySelector("#header");
const aside = document.querySelector("#aside");

const logoFooter = document.querySelector(".footer #logo");

const footerDropdown = document.querySelectorAll(".footer__title");

footerDropdown.forEach((el) => {
  el.addEventListener("click", () => {
    el.classList.toggle("active");
  });
});

logoFooter.addEventListener("click", () => {
  window.scroll({ top: 0, behavior: "smooth" });
});

const hideHeader = () => {
  header.classList.add("header--onscroll");
};

const showHeader = () => {
  header.classList.remove("header--onscroll");
};

let lastScrollTop = 0;
window.addEventListener("scroll", () => {
  const st = window.pageYOffset;
  st >= 200
    ? (hideHeader(),
      st < lastScrollTop && header.classList.remove("header--hide"),
      (lastScrollTop = st <= 0 ? 0 : st))
    : showHeader();
});
